import { useState } from "react";
import { Row, Col, Drawer } from "antd";
import { withTranslation, TFunction } from "react-i18next";
import Container from "../../common/Container";
import { SvgIcon } from "../../common/SvgIcon";

import {
  HeaderSection,
  LogoContainer,
  Burger,
  CustomNavLinkSmall,
  Label,
  Span,
  LanguageSwitchContainer,
  LanguageSwitch,
  MobileLogo,
  DesktopLogo,
} from "./styles";
import i18n from "i18next";

const Header = ({ t }: { t: TFunction }) => {
  const [visible, setVisibility] = useState(false);

  const handleChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  const toggleButton = () => {
    setVisibility(!visible);
  };

  const MenuItem = () => {
    const scrollTo = (id: string) => {
      const element = document.getElementById(id) as HTMLDivElement;
      element.scrollIntoView({
        behavior: "smooth",
      });
      setVisibility(false);
    };
    return (
      <>
        <CustomNavLinkSmall onClick={() => scrollTo("header")}>
          <Span>{t("Home")}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall onClick={() => scrollTo("about")}>
          <Span>{t("About")}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall onClick={() => scrollTo("services")}>
          <Span>{t("Services")}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall
          onClick={() => scrollTo("contacts")}
        >
          <Span>
            {t("Contacts")}
          </Span>
        </CustomNavLinkSmall>
      </>
    );
  };

  return (
    <HeaderSection id='header'>
      <Container>
        <Row justify="space-between">
          <Col lg={12} md={12} sm={12} xs={12}>

            <LogoContainer to="/" aria-label="homepage">
              <MobileLogo>
                <SvgIcon
                  src="logo.svg"
                  aria-label="homepage"
                  width="138px"
                  height="32px"
                />
              </MobileLogo>
              <DesktopLogo>
                <SvgIcon
                  src="logo.svg"
                  aria-label="homepage"
                  width="264px"
                  height="64px"
                />
              </DesktopLogo>
            </LogoContainer>
          </Col>
          <Col lg={8} md={8} sm={8} xs={8}>
            <Row justify="end">
              <LanguageSwitchContainer>
                <LanguageSwitch onClick={() => handleChange("en")} className={i18n.language === 'en' ? 'current' : 'default'}>
                  EN
                </LanguageSwitch>
                <LanguageSwitch onClick={() => handleChange("de")} className={i18n.language === 'de' ? 'current' : 'default'}>
                  DE
                </LanguageSwitch>
              </LanguageSwitchContainer>
              <Burger onClick={toggleButton}>
                <SvgIcon src="nav-vector.svg" width="36" height="36" />
              </Burger>
            </Row>
          </Col>
        </Row>
        <Drawer closable={false} open={visible} onClose={toggleButton}>
          <Col style={{ marginBottom: "4.8rem" }}>
            <Label onClick={toggleButton}>
              <Col span={24}>
                <Row justify="end">
                  <SvgIcon src="nav-close-vector.svg" width="24" height="24" />
                </Row>
              </Col>
            </Label>
          </Col>
          <MenuItem />
        </Drawer>
      </Container>
    </HeaderSection>
  );
};

export default withTranslation()(Header);
