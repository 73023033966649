import { createGlobalStyle } from "styled-components";

export const Styles = createGlobalStyle`

    @font-face {
        font-family: "Unbounded";
        src: url("/fonts/Unbounded.ttf") format("truetype");
        font-style: normal;
    }

    @font-face {
        font-family: "Unbounded ExtraLight";
        src: url("fonts/Unbounded-ExtraLight.ttf") format("truetype");
        font-style: normal;
    }

    @font-face {
        font-family: "Unbounded Light";
        src: url("fonts/Unbounded-Light.ttf") format("truetype");
        font-style: normal;
    }

    @font-face {
        font-family: "Unbounded Regular";
        src: url("fonts/Unbounded-Regular.ttf") format("truetype");
        font-style: normal;
    }

    @font-face {
        font-family: "Unbounded Medium";
        src: url("fonts/Unbounded-Medium.ttf") format("truetype");
        font-style: normal;
    }

    @font-face {
        font-family: "Unbounded SemiBold";
        src: url("fonts/Unbounded-SemiBold.ttf") format("truetype");
        font-style: normal;
    }

    @font-face {
        font-family: "Unbounded Bold";
        src: url("fonts/Unbounded-Bold.ttf") format("truetype");
        font-style: normal;
    }

    @font-face {
        font-family: "Unbounded ExtraBold";
        src: url("fonts/Unbounded-ExtraBold.ttf") format("truetype");
        font-style: normal;
    }

    @font-face {
        font-family: "Unbounded Black";
        src: url("fonts/Unbounded-Black.ttf") format("truetype");
        font-style: normal;
    }

    body,
    html,
    a {
        font-family: 'Unbounded Light', sans-serif;
    }


    body {
        margin:0;
        padding:0;
        border: 0;
        outline: 0;
        background: #F2F2F2;
        overflow-x: hidden;
    }

    a:hover {
        color: #31312E;
    }

    input,
    textarea {
        border: 0;
        background: rgb(241, 242, 243);
        transition: all 0.3s ease-in-out;  
        outline: none;
        width: 100%;  
        padding: 1rem 1.25rem;

        :focus-within {
            background: none;
            box-shadow: #31312e 0px 0px 0px 1px;
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: "Unbounded Light", serif;
        text-transform: uppercase;
        line-height: 1;
        color: #31312E;
        font-size: 64px;

        @media only screen and (max-width: 890px) {
          font-size: 42px;
        }
      
        @media only screen and (max-width: 414px) {
          font-size: 30px;
        }
    }

    p {
        color: #31312E;
        font-size: 21px;        
        line-height: 1.41;
    }

    h1 {
        font-weight: 600;
    }

    a {
        text-decoration: none;
        outline: none;
        color: #31312E;

        :hover {
            color: #31312e;
        }
    }
    
    *:focus {
        outline: none;
    }

    .about-block-image svg {
        text-align: center;
    }

    .ant-drawer-body {
        display: flex;
        flex-direction: column;
        text-align: left;
        padding-top: 1.5rem;
        background: #F2F2F2;
    }

    .ant-drawer-content-wrapper {
        width: 100% !important;
    }

    .ant-modal {
      max-width: 100vw !important;
      overflow: hidden;
      width: 100vw !important;
      margin: 0 !important;
      top: 0 !important;
      transform: none !important;
      padding-bottom: 0 !important;
    }

    .ant-modal-content {
        background: rgba(37, 37, 36, 0.97);
        height: 100vh !important;
        padding: 0 !important;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    .ant-modal-body {
        height: 100vh;
        overflow-y: scroll;
         @media only screen and (max-width: 890px) {
          padding: 0;
        }
      
        @media only screen and (max-width: 414px) {
        //   font-size: 30px;
        }
    }

    .ant-modal-close-x {
        color: #fff;
    }
`;
