import styled from "styled-components";
import { Link } from "react-router-dom";

export const HeaderSection = styled("header")`
  padding: 1rem 0;

  .ant-row-space-between {
    align-items: center;
    text-align: center;
  }
  .ant-drawer-content {
    background: #F2F2F2;
  }
`;

export const LogoContainer = styled(Link)`
  display: flex;
  width: 264px;
`;

export const MobileLogo = styled("div")`
  @media only screen and (max-width: 820px) {
    display: block;
  }

  display: none;
`;

export const DesktopLogo = styled("div")`
  @media only screen and (max-width: 820px) {
    display: none;
  }

  display: block;
`;

export const NavLink = styled("div")`
  display: inline-block;
  text-align: center;
`;

export const CustomNavLink = styled("div")`
  width: 203px;
  display: inline-block;

  @media only screen and (max-width: 411px) {
    width: 150px;
  }

  @media only screen and (max-width: 320px) {
    width: 118px;
  }
`;

export const Burger = styled("div")`
  display: block;
  cursor: pointer;
  margin-left: 56px;
  margin-right: 0px;
  @media only screen and (max-width: 820px) {
    margin-left: 14px;
    img  {
      width: 24px;
      height: 24px;
    }
  }
 img {
  &:hover,
  &:active,
  &:focus {
    filter: 
      brightness(0) saturate(100%) invert(55%) sepia(67%) saturate(2990%) hue-rotate(140deg) brightness(106%) contrast(97%)
  }
}


  
`;

export const NotHidden = styled("div")`
  @media only screen and (max-width: 820px) {
    display: none;
  }
`;

export const Menu = styled("h5")`
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
`;

export const CustomNavLinkSmall = styled(NavLink)`
  font-size: 30px;
  line-height: 2;
  color: #31312E;
  transition: color 0.2s ease-in;
  text-transform: uppercase;
  width: 100%;
  max-width: 200px;
  margin: 0 auto;
  text-align: left;
`;

export const Label = styled("span")`
  font-weight: 500;
  cursor: pointer;
  color: #404041;
  text-align: right;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  img {
    &:hover,
    &:active,
    &:focus {
      filter: 
        brightness(0) saturate(100%) invert(55%) sepia(67%) saturate(2990%) hue-rotate(140deg) brightness(106%) contrast(97%)
    }
  }
`;


export const Span = styled("span")`
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover,
  &:active,
  &:focus {
    color: rgb(4, 202, 189);
    text-underline-position: under;
    text-decoration: rgb(4, 202, 189);
  }
`;


export const LanguageSwitch = styled("div")`
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  padding-right: 10px;
  font-size: 20px;
  line-height: 36px;
  &:hover,
  &:active,
  &:focus {
    font-weight: 900;
    color: rgb(4, 202, 189);
  }
    
  @media screen and (max-width: 820px) {
    font-size: 12px;
    line-height: 24px;
  }

  &.current {
    color: rgb(4, 202, 189);
    text-underline-position: under;
    text-decoration: rgb(4, 202, 189);
  }
`;

export const LanguageSwitchContainer = styled("div")`
  display: flex;
  justify-content: space-between;
  padding-right: 24x;

  @media screen and (max-width: 820px) {
    padding-right: 57x;
  }
`;
